import { Snackbar as MuiSnackbar, type SnackbarProps as MuiSnackbarProps } from "@mui/base";
import { twMerge } from "tailwind-merge";

type SnackbarProps = MuiSnackbarProps;

export type SnackbarCloseReason = "timeout" | "clickaway" | "escapeKeyDown";

export default function Snackbar({ children, ...props }: SnackbarProps) {
  return (
    <MuiSnackbar
      {...props}
      className={twMerge(
        "fixed bottom-2 left-1/2 z-50 -translate-x-1/2 transform animate-notification",
        props.className
      )}
    >
      <div className="animate-growIn rounded-lg shadow-lg">{children}</div>
    </MuiSnackbar>
  );
}
